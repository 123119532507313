import React, {createRef, ReactElement} from "react";
import {
  Box,
  Button,
  Card,
  Fab,
  FormControl,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  SvgIcon,
  Tooltip,
  Typography
} from "@mui/material";
import {TitleBar} from "./TitleBar";
import {SidebarItem} from "./Sidebar";
import {
  BORDER_RADIUS,
  DIVIDER,
  DW_LG,
  PAGE_FRAGMENT_HALF_WIDTH,
  PAGE_FRAGMENT_WIDTH,
  PD_LG,
  PD_MD,
  PD_SM,
  PD_XSM,
  SZ_ACTION_PANEL,
  SZ_MD,
  SZ_SM,
  SZ_XXXLG
} from "shared/dimens";
import {StyledBoxColumn, StyledBoxRow, StyledEmpty, StyledSpan, StyledVerticalDivider} from "shared/StyledComponents";
import {
  Action,
  ActionActionsListPopover,
  ActionBase,
  ActionGroup,
  ActionPopover,
  ActionSelect,
  ActionType,
  EmptyConfig,
  MenuOption,
  TabOptionItem,
  TabOptions
} from "./types";
import {black, lightGray, translucentBlack, white} from "shared/colors";
import {
  BrokenImageOutlined,
  CheckOutlined,
  CloseOutlined,
  ErrorOutlineOutlined,
  ExpandMoreOutlined
} from "@mui/icons-material";
import {$KTS, KeyText} from "shared/types";
import {BaseApp, DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN, DialogProps, MenuProps} from "shared/BaseApp";
import {BaseFragment, BaseFragmentProps, BaseFragmentState, createInput, InputSpec} from "./BaseFragment";
import {PopoverContainer} from "./PopoverContainer";
import {TabInfo} from "./TabsContainer";
import {PathComponent} from "../index";

export interface FabItemSpec {
  readonly iconType: typeof SvgIcon;
  readonly onClick: (event) => void;
}

export type ToolbarModeProps = {
  id: string,
  title: string,
  onDoneButtonClicked?: (event) => void,
  onExitButtonClicked?: (event) => void,
}

export class ToolbarMode {

  constructor(readonly props: ToolbarModeProps, readonly args: any) {
  }
}

export type ToolbarFilters = {
  label?: string,
  items: ToolbarFilterItem[],
}

export class ToolbarFilterItem {

  private selected: boolean = false;

  iconType: typeof SvgIcon;

  constructor(readonly id: string, readonly text: string, readonly onSelectionChanged?: (item: ToolbarFilterItem, selected: boolean) => void, readonly onClicked?: (item: ToolbarFilterItem) => boolean) {
  }

  setIconType(iconType: typeof SvgIcon): ToolbarFilterItem {
    this.iconType = iconType;
    return this;
  }

  isSelected(): boolean {
    return this.selected;
  }

  setSelected(selected: boolean) {
    this.selected = selected;
    this.onSelectionChanged?.(this, selected);
  }
}

export interface FabSpec extends FabItemSpec {
  variant?: 'circular' | 'extended',
  text?: string,
  inputSpec?: InputSpec,
}

export enum PageFragmentSelectModeType {
  SELECT_ONE,
  SELECT_MULTI,
}

export type PageFragmentSelectModeConfig = {
  title?: string,
  type?: PageFragmentSelectModeType,
  initialSelection?: any,
  onSelected: (event, ...args: any) => void;
}

export type PageFragmentProps = BaseFragmentProps & {
  selectModeConfig?: PageFragmentSelectModeConfig,
  toolbarHidden?: boolean,
  titleHidden?: boolean,
  selectedItem?: SidebarItem,
  showCloseDialogButton?: boolean,
  onCloseDialog?: () => void,
}

export type PageFragmentState = BaseFragmentState & {
  title?: string,
  logo?: string,
  multiSelectItems?: any[],
  actionButtonText?: string,
  actionButtonDisabled?: boolean,
  selectedToolbarTabId?: string,
  toolbarMode?: ToolbarMode,
  _toolbarValues?: any,
}

export abstract class PageFragment<P extends PageFragmentProps = PageFragmentProps, S extends PageFragmentState = PageFragmentState> extends BaseFragment<P, S> {

  // Style flags
  private static readonly STYLE_BACK_BUTTON = 0x1;
  static readonly STYLE_BACK_BUTTON_FLAG = 1 << 0;

  private static readonly STYLE_FULLSCREEN_BUTTON = 0x2;
  static readonly STYLE_FULLSCREEN_BUTTON_FLAG = 1 << 1;

  private static readonly STYLE_HIDE_SIDEBAR_BUTTON = 0x4;
  static readonly STYLE_HIDE_SIDEBAR_BUTTON_FLAG = 1 << 2;

  // private static readonly STYLE_SCROLLABLE_MASK = 0x2;
  // static readonly STYLE_SCROLLABLE_FLAG = 1 << 1;
  // static readonly STYLE_FITS_SYSTEM_WINDOWS_MASK = 0x4;
  // static readonly STYLE_FITS_SYSTEM_WINDOWS_FLAG = 1 << 2;

  private static readonly STYLE_SEARCH_CONTAINER_MASK = 0x8;
  static readonly STYLE_SEARCH_CONTAINER_FLAG = 1 << 3;

  private static readonly STYLE_ACTION_PANEL_TYPE_MASK = 0x7 << 4;
  static readonly STYLE_ACTION_PANEL_TYPE_BUTTON_FLAG = 1 << 4;
  static readonly STYLE_ACTION_PANEL_TYPE_TOOLS_BUTTON_FLAG = 2 << 4;
  static readonly STYLE_ACTION_PANEL_TYPE_TEXT_BUTTON_FLAG = 3 << 4;
  static readonly STYLE_ACTION_PANEL_TYPE_BUTTONSTRIP_FLAG = 4 << 4;

  private static readonly STYLE_ACTION_PANEL_APPEARANCE_MASK = 0x80;
  static readonly STYLE_ACTION_PANEL_TRANSLUCENT_FLAG = 1 << 7;

  private static readonly STYLE_CONTEXT_CONTROLS_MASK = 0x100;
  static readonly STYLE_CONTEXT_CONTROLS_FLAG = 1 << 8;

  private static readonly STYLE_TOOLBAR_TYPE_MASK = 0x200;
  static readonly STYLE_TOOLBAR_TYPE_FLAG: number = 1 << 9;

  private static readonly STYLE_CONTAINER_APPEARANCE_MASK = 0xC00;
  static readonly STYLE_CONTAINER_OPAQUE_FLAG = 1 << 10;
  static readonly STYLE_CONTAINER_WASH_FLAG = 1 << 11;

  protected static readonly defaultState: PageFragmentState = {};

  protected readonly fabRef = createRef<HTMLButtonElement>();

  constructor(props: P, context: any) {
    super(props, context);
    this.state = this.onCreateState();
  }

  static nestedPaths(): PathComponent[] {
    return [];
  }

  protected onCreateState(): S {
    return {
      ...super.onCreateState(),
      ...PageFragment.defaultState,
      title: this.getTitle(),
    } as S;
  }

  protected getTitle(): string | null | undefined {
    return undefined;
  }

  protected getContainerId(): string | null | undefined {
    return undefined;
  }

  protected renderContainerContent(): React.ReactElement | null {
    //@ts-ignore
    const nestedPaths: PathComponent[] = this.constructor.nestedPaths();
    const handle = this.props.path?.matches[this.props.path.matches.length - 1]?.handle;
    if ((handle?.containerId?.length > 0) && handle.containerId === this.getContainerId()) {
      return nestedPaths.find(path => path.path === handle.path)?.render(this.props.path);
    }
    if ((this.styleFlags() & PageFragment.STYLE_CONTAINER_APPEARANCE_MASK) === PageFragment.STYLE_CONTAINER_OPAQUE_FLAG) {
      return <Card>
        {this.renderContainerContentInternal()}
      </Card>;
    } else {
      return this.renderContainerContentInternal();
    }
  }

  protected getIntrinsicHeight(): number | string {
    return 0;
  }

  private isFullscreen?: boolean;

  private renderContainerContentInternal() {
    const hasTitleBar = !this.props.toolbarHidden;
    //let background = ((this.styleFlags() & PageFragment.STYLE_BG_MASK) === PageFragment.STYLE_BG_STYLED_FLAG) ? `url("${BackgroundImage}") repeat` : null;
    let backgroundColor = BaseApp.CONTEXT.getAppConfig()?.theme.palette.background.default || lightGray;// ((this.styleFlags() & PageFragment.STYLE_CONTAINER_APPEARANCE_MASK) === PageFragment.STYLE_CONTAINER_WASH_FLAG) ? this.theme.palette.background.paper : null;
    const hasToolbar = (this.styleFlags() & PageFragment.STYLE_TOOLBAR_TYPE_MASK) === PageFragment.STYLE_TOOLBAR_TYPE_FLAG;
    const toolbarMode = this.state?.toolbarMode;
    return <Box style={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: this.getIntrinsicHeight(),
      flexGrow: 1,
      backgroundColor: backgroundColor,
    }}>
      {hasTitleBar ? <TitleBar variant='decorated'
                               text={this.props.selectModeConfig?.title || this.state?.title}
                               logo={this.state?.logo}
                               titleHidden={this.props.titleHidden}
                               toolbar={hasToolbar ? this.renderToolbar(toolbarMode) : null}
                               toolbarDescription={hasToolbar ? this.getToolbarDescription(toolbarMode?.props.id) : null}
                               toolbarDetail={hasToolbar ? this.getToolbarDetail(toolbarMode?.props.id) : null}
                               tabOptions={this.createToolbarTabOptions()}
                               filters={this.getToolbarFilters()}
                               actions={this.createToolbarActions()}
                               popovers={this.createToolbarPopovers()}
                               menuOptions={this.createMenuOptions()}
                               onCloseDialog={this.createCloseDialog()}
                               onBackButtonClicked={() => this.onBackButtonClicked()}
                               onMenuButtonClicked={(menuOption) => this.onMenuButtonClicked(menuOption)}
                               showBackButton={(this.styleFlags() & PageFragment.STYLE_BACK_BUTTON) !== 0}
                               showFullscreenButton={(this.styleFlags() & PageFragment.STYLE_FULLSCREEN_BUTTON) !== 0}
                               showHideSidebarButton={(this.styleFlags() & PageFragment.STYLE_HIDE_SIDEBAR_BUTTON) !== 0}
                               onFullscreenButtonClicked={(exit?: boolean) => {
                                 this.isFullscreen = !exit;
                                 BaseApp.CONTEXT.setFullscreenElement(exit ? null : this.renderContainerContentInternal());
                               }}
                               isFullscreen={this.isFullscreen}/>
        : null}
      {(hasTitleBar ? this.renderContentAdjusted() : this.renderContent()) || (!this.props.selectedItem && this.renderUnselected())}
      {this.renderActionPanel()}
      {this.renderFab()}
    </Box>;
  }

  setToolbarMode(props: ToolbarModeProps, args?: any) {
    const oldMode = this.state.toolbarMode;
    const newMode = new ToolbarMode(props, args);
    this.setState({
      toolbarMode: newMode,
    });
    this.onToolbarModeDeactivated(oldMode);
    this.onToolbarModeActivated(newMode);
  }

  clearToolbarMode() {
    const oldMode = this.state.toolbarMode;
    this.setState({
      toolbarMode: null,
    });
    this.onToolbarModeDeactivated(oldMode);
    this.onToolbarModeActivated(null);
  }

  protected onToolbarModeActivated(toolbarMode: ToolbarMode) {
  }

  protected onToolbarModeDeactivated(toolbarMode: ToolbarMode) {
  }

  protected renderPopover(title: string, anchorEl: any, content: ReactElement, onClose: () => void) {
    return <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => onClose()}
      anchorOrigin={{horizontal: "center", vertical: "bottom"}}
      transformOrigin={{horizontal: "center", vertical: "top"}}>
      <PopoverContainer title={title}>
        {content}
      </PopoverContainer>
    </Popover>
  }

  private renderToolbar(toolbarMode: ToolbarMode): ReactElement {
    // Don't render toolbar if we're "select"ing only (e.g. a fragment dedicated to selecting from a table/list).
    if (this.props.selectModeConfig) {
      return null;
    }
    const toolbar = this.getToolbar(toolbarMode?.props.id);
    if (!toolbar) {
      return null;
    }
    return <Box key={toolbarMode?.props.id} style={{display: "flex", flexGrow: 1, alignItems: "center"}}>
      {toolbarMode?.props.title ?
        <Typography variant="body1"
                    style={{marginLeft: PD_SM, marginRight: PD_SM}}>{toolbarMode.props.title}</Typography> : null}
      {toolbar}
      {toolbarMode?.props.onDoneButtonClicked ? this.renderToolbarButton(new Action("Done", (event) => {
        this.clearToolbarMode();
        toolbarMode.props.onDoneButtonClicked(event);
      }, CheckOutlined)) : null}
      {toolbarMode?.props.onExitButtonClicked ? this.renderToolbarButton(new Action("Exit", (event) => {
        toolbarMode.props.onExitButtonClicked(event);
        this.clearToolbarMode();
      }, CloseOutlined)) : null}
    </Box>;
  }

  protected getToolbarDetail(toolbarModeId: string): ReactElement | null {
    return null;
  }

  private renderFab(): ReactElement {
    let fabSpec = this.createFabSpec();
    if (fabSpec) {
      let IconType = fabSpec.iconType;
      let input = createInput(fabSpec.inputSpec);
      const hasActionPanel = (this.styleFlags() & PageFragment.STYLE_ACTION_PANEL_TYPE_MASK) !== 0;
      return <Fab variant={fabSpec.variant}
                  ref={this.fabRef}
                  color="secondary"
                  onClick={fabSpec.onClick}
                  style={{
                    position: 'absolute',
                    right: PD_SM,
                    bottom: (PD_MD + (hasActionPanel ? SZ_ACTION_PANEL : 0))
                  }}>
        {input}
        <StyledBoxRow style={{alignItems: "center"}}>
          <IconType/>
          {fabSpec.variant === 'extended' ? <Typography variant="caption" style={{
            marginLeft: PD_XSM,
            marginRight: PD_XSM,
            fontWeight: "bold"
          }}>{fabSpec.text}</Typography> : null}
        </StyledBoxRow>
      </Fab>
    }
    return null;
  }

  protected renderEmpty(style?: any): ReactElement {
    const emptyConfig = this.getEmptyConfig();
    if (!emptyConfig) {
      return null;
    }
    return <StyledEmpty emptyConfig={emptyConfig} style={style}/>;
  }

  protected renderUnselected(): ReactElement {
    const unselectedConfig = this.getUnselectedConfig();
    if (!unselectedConfig) {
      return null;
    }
    return <StyledEmpty emptyConfig={unselectedConfig}/>;
  }

  protected renderError(config?: EmptyConfig): ReactElement {
    const errorConfig = config || this.getErrorConfig();
    if (!errorConfig) {
      return null;
    }
    return <StyledEmpty emptyConfig={errorConfig}/>;
  }

  protected renderNotFound(): ReactElement {
    return <StyledEmpty emptyConfig={{
      iconType: BrokenImageOutlined,
      title: "Not found",
      text: "This content may have been deleted, or you may not have the required permissions for access.",
    }}/>;
  }

  protected getEmptyConfig(): EmptyConfig {
    return null;
  }

  protected getUnselectedConfig(): EmptyConfig {
    return null;
  }

  protected getErrorConfig(): EmptyConfig {
    return {
      title: "Something went wrong",
      text: "This page does not exist or may not be visible to you.",
      iconType: ErrorOutlineOutlined,
    };
  }

  protected styleFlags(): number {
    return 0;
  }

  createFabSpec(): FabSpec {
    return null;
  }

  createToolbarTabOptions(): TabOptions {
    const tabs = this.getToolbarTabs();
    if (tabs) {
      return new TabOptions(tabs.filter(tab => !tab.hidden).map((tab, index) => {
        const tabOptionItem = new TabOptionItem(tab.type, tab.text, null, item => {
          if (item.id !== this.state.selectedToolbarTabId) {
            this.setState({
              selectedToolbarTabId: item.id,
            });
          }
          return true;
        });
        tabOptionItem.setSelected(tab.type === this.state.selectedToolbarTabId);
        return tabOptionItem;
      }));
    }
    return null;
  }

  getSelectedTab(): TabInfo<any> | undefined {
    return this.getToolbarTabs()?.find(tab => tab.type === this.state.selectedToolbarTabId);
  }

  getToolbarTabs(): TabInfo<any>[] {
    return null
  }

  private toolbarFilters: ToolbarFilters;

  private getToolbarFilters(): ToolbarFilters {
    if (this.toolbarFilters === undefined) {
      this.toolbarFilters = this.createToolbarFilters();
    }
    return this.toolbarFilters;
  }

  createToolbarFilters(): ToolbarFilters {
    return null;
  }

  createToolbarActions(): Action[] {
    return null;
  }

  createToolbarPopovers(): ReactElement[] {
    return null;
  }

  createMenuOptions(): MenuOption[] {
    return null;
  }

  createCloseDialog(): () => void {
    if (!this.props.showCloseDialogButton) {
      return null;
    }
    return () => {
      BaseApp.CONTEXT.hideDialog()
    };
  }

  onMenuButtonClicked(menuOption: MenuOption): boolean {
    return false;
  }

  onBackButtonClicked() {
    this.props.path.navigate(-1);
  }

  protected setActionButtonEnabled(enabled: boolean) {
    this.setState({
      actionButtonDisabled: !enabled,
    })
  }

  protected getToolbar(toolbarModeId: string | null): ReactElement {
    return null;
  }

  protected getToolbarDescription(toolbarModeId: string | null): string {
    return null;
  }

  protected renderInToolbarContainer1(children: ReactElement): ReactElement {
    return this.renderInToolbarContainer([children]);
  }

  protected renderInToolbarContainer(children: ReactElement[]): ReactElement {
    return <StyledBoxRow style={{paddingLeft: PD_SM, paddingRight: PD_SM, height: SZ_SM, alignItems: "center"}}>
      {children}
    </StyledBoxRow>;
  }

  protected renderToolbarButtonsInToolbarContainer(bases: ActionBase[], ...args: any[]): ReactElement {
    return this.renderInToolbarContainer(bases.map(base => this.renderToolbarButtonEx(base, ...args)));
  }

  protected renderToolbarButton(action: Action, disabled?: boolean): ReactElement {
    const IconType = action.iconType;
    return <Tooltip title={action.text} arrow>
      <Button disabled={disabled} style={{width: SZ_SM, height: SZ_SM, color: black, opacity: (disabled ? 0.5 : 1)}}
              onClick={(event) => {
                action.onClick(event);
              }}>
        <IconType/>
      </Button>
    </Tooltip>;
  }

  protected renderToolbarButtonEx(base: ActionBase, ...args: any[]) {
    return PageFragment.renderPageToolbarButtonEx(base, ...args);
  }

  static renderPageToolbarButtonEx(base: ActionBase, ...args: any[]) {
    return this.renderPageToolbarButtonExInternal(base, null, ...args);
  }

  private static renderPageToolbarButtonExInternal(base: ActionBase, onClickOverride?: (action: Action, event, ...args: any[]) => void, ...args: any[]) {
    if (base.type === ActionType.SEPARATOR) {
      return <StyledVerticalDivider/>;
    } else if (base.type === ActionType.SPACE) {
      return <StyledSpan/>;
    } else if (base.type === ActionType.GROUP) {
      const group = base as ActionGroup;
      return <StyledBoxRow style={{alignItems: "center"}}>
        {group.label ? <Typography variant="body2" style={{fontWeight: "bold"}}>{group.label}</Typography> : null}
        {group.filterButtons().map(action => PageFragment.renderPageToolbarButtonExInternal(action, group.onClickOverride(), ...args))}
      </StyledBoxRow>;
    } else if (base.type === ActionType.SELECT) {
      const select = base as ActionSelect;
      return <Button disabled={select.isDisabledFn?.()}
                     startIcon={select.iconType ? <select.iconType/> : null}
                     endIcon={<ExpandMoreOutlined/>}
                     onClick={(event) => BaseApp.CONTEXT.showPopover(event.target as HTMLElement, null, () => <List>
                       {select.filterButtons().map(button => <ListItemButton sx={{gap: 2}} onClick={() => {
                         BaseApp.CONTEXT.hidePopover();
                         button.onClick?.();
                       }}>
                         {button.iconType ? <button.iconType/> : null}
                         <ListItemText>{button.text}</ListItemText>
                       </ListItemButton>)}
                     </List>)}>
        {select.text}
      </Button>;
    } else if (base.type === ActionType.POPOVER) {
      const popover = base as ActionPopover;
      return <Button disabled={popover.isDisabledFn?.()}
                     startIcon={popover.iconType ? <popover.iconType/> : null}
                     endIcon={<ExpandMoreOutlined/>}
                     onClick={(event) => BaseApp.CONTEXT.showPopover(event.target as HTMLElement, null, () => popover.render())}>
        {popover.text}
      </Button>;
    } else if (base.type === ActionType.ACTIONS_LIST_POPOVER) {
      const popover = base as ActionActionsListPopover;
      return <Button disabled={popover.isDisabledFn?.()}
                     startIcon={popover.iconType ? <popover.iconType/> : null}
                     endIcon={<ExpandMoreOutlined/>}
                     onClick={(event) => BaseApp.CONTEXT.showActionsListPopover(event.target as HTMLElement, null, popover.actions)}>
        {popover.text}
      </Button>;
    }
    const action = base as Action;
    let content;
    let style = {};
    if (action.orientation === "vertical") {
      style = {paddingLeft: 0, paddingRight: 0, minWidth: 48};
      content = <Box style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        {this.getIconForAction(action)}
        <Typography variant="caption" style={{fontWeight: "bold"}}>{action.text}</Typography>
      </Box>
    } else {
      content = action.text;
    }
    return <Button
      style={{flexShrink: 0, paddingLeft: PD_MD, paddingRight: PD_MD, ...style}}
      variant={((action.variant === "contained") || action.selected || action.isSelectedFn?.()) ? "contained" : "text"}
      disabled={action.disabled || action.isDisabledFn?.()}
      color={action.destructive ? "error" : (action.secondary ? "secondary" : "primary")}
      startIcon={action.orientation !== "vertical" ? this.getIconForAction(action) : null}
      onClick={(event) => {
        if (onClickOverride) {
          onClickOverride(action, event, ...args);
        } else {
          action.onClick?.(event, ...args);
        }
      }}>
      {content}
      {action.badgeText
        ? <Typography style={{
          background: translucentBlack,
          color: white,
          borderRadius: BORDER_RADIUS,
          paddingLeft: PD_XSM,
          paddingRight: PD_XSM,
          marginLeft: PD_MD
        }}>{action.badgeText}</Typography>
        : null}
    </Button>;
  }

  private static getIconForAction(action: Action): ReactElement | null {
    if (action.customIconRenderer) {
      return action.customIconRenderer();
    }
    if (!action.iconType) {
      return null;
    }
    return action.iconFlipVertical ? <action.iconType style={{transform: "rotate(180deg)"}}/> : <action.iconType/>;
  }

  protected renderToolbarSelect(id: string, label: string, values: string[], initialSelectedIndex: number = 0) {
    return this.renderToolbarKeys(id, label, values.map(v => $KTS(v, v)), initialSelectedIndex);
  }

  protected renderToolbarKeys(id: string, label: string, values: KeyText[], initialSelectedIndex: number = 0) {
    if (!this.state._toolbarValues?.[id]) {
      this.setState({
        _toolbarValues: {
          ...this.state._toolbarValues || {},
          [id]: values[initialSelectedIndex].key,
        },
      });
    }
    return <FormControl variant="standard" size="small"
                        style={{minWidth: SZ_XXXLG, marginLeft: PD_SM, marginRight: PD_SM}}>
      <InputLabel>{label}</InputLabel>
      <Select id={id}
              value={this.state._toolbarValues?.[id] || values[initialSelectedIndex].key}
              onChange={event => this.onToolbarSelectChanged(event, id)}>
        {values.map(value => <MenuItem value={value.key}>{value.text}</MenuItem>)}
      </Select>
    </FormControl>

  }

  private onToolbarSelectChanged(event, id: string) {
    this.setState({
      _toolbarValues: {
        ...this.state._toolbarValues || {},
        [id]: event.target.value,
      },
    });
  }

  protected renderToolbarSeparator() {
    return <div style={{width: 1, height: SZ_MD, borderLeft: DIVIDER}}/>
  }

  protected getActionButtonText(): string {
    return null;
  }

  onActionButtonClicked(event?) {
  }

  protected isCancelable(): boolean {
    return false;
  }

  protected getActionTextViewText(): string {
    return null;
  }

  protected getActionButtonstrip(): Action[] {
    return null;
  }

  protected createActionToolsView(): ReactElement {
    return null;
  }

  protected createFloatingView(): ReactElement {
    return null;
  }

  private renderActionPanel(): ReactElement {
    if (this.props.selectModeConfig) {
      return <Box display="flex" flexDirection="column" style={{
        position: 'sticky',
        padding: PD_SM,
        background: this.theme.palette.background.paper,
        columnGap: PD_SM,
        left: 0,
        right: 0,
        bottom: 0,
        borderTop: DIVIDER,
      }}>
        <Button style={{width: "100%", maxWidth: PAGE_FRAGMENT_WIDTH, margin: "auto"}} variant="contained"
                disabled={!(this.state.multiSelectItems?.length > 0)}
                onClick={(event) => this.props.selectModeConfig.onSelected(event, this.state.multiSelectItems)}>
          Select
        </Button>
      </Box>;
    }
    const actionPanelType = this.styleFlags() & PageFragment.STYLE_ACTION_PANEL_TYPE_MASK;
    if (actionPanelType) {
      if (actionPanelType === PageFragment.STYLE_ACTION_PANEL_TYPE_BUTTON_FLAG) {
        return <StyledBoxColumn style={{
          position: 'sticky',
          paddingTop: PD_SM,
          paddingBottom: PD_SM,
          width: "100%",
          background: this.theme.palette.background.paper,
          left: 0,
          right: 0,
          bottom: 0,
          alignItems: "center",
          borderTop: DIVIDER,
        }}>
          <StyledBoxColumn
            style={{
              width: "80%",
              maxWidth: PAGE_FRAGMENT_HALF_WIDTH,
            }}>
            <Button
              variant="contained"
              disabled={this.state.actionButtonDisabled}
              onClick={(event) => this.onActionButtonClicked(event)}>
              {this.state.actionButtonText || this.getActionButtonText()}
            </Button>
            {this.isCancelable() ?
              <Button
                variant="text"
                onClick={() => this.props.onCloseDialog?.()}>Cancel</Button>
              : null}
          </StyledBoxColumn>
        </StyledBoxColumn>
      } else if (actionPanelType === PageFragment.STYLE_ACTION_PANEL_TYPE_TOOLS_BUTTON_FLAG) {
        return <Box style={{
          position: 'sticky',
          padding: PD_SM,
          background: this.theme.palette.background.paper,
          left: 0,
          right: 0,
          bottom: 0,
          borderTop: DIVIDER,
        }}>
          <Box style={{
            display: "flex",
            margin: "auto",
            gap: PD_SM,
          }}>
            {this.createActionToolsView()}
            <Button variant="contained"
                    style={{maxWidth: PAGE_FRAGMENT_WIDTH}}
                    onClick={(event) => this.onActionButtonClicked(event)}>{this.state.actionButtonText || this.getActionButtonText()}</Button>
          </Box>
        </Box>
      }
    } else {
      return null;
    }
  }

  private renderContentAdjusted(): ReactElement {
    return <Box
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        position: "relative",
        overflowY: "scroll",
      }}>
      {this.renderContent()}
    </Box>;
  }

  renderContent(): ReactElement {
    return null;
  }

  renderDialogWithId(dialogId: string, ...dialogArgs: any[]): ReactElement {
    return null;
  }

  showDialog(props: DialogProps, renderer: (props: DialogProps) => ReactElement, onClose?: () => void) {
    BaseApp.CONTEXT.showDialog(props, renderer, onClose);
  }

  showDialogWithId(dialogId: string, dialogFlags: number = 0, ...dialogArgs: any[]) {
    BaseApp.CONTEXT.showDialog({
      id: dialogId,
      flags: dialogFlags,
      args: dialogArgs
    } as DialogProps, (props: DialogProps) => this.renderDialogWithId(props.id, ...props.args));
  }

  hideDialog(id?: string) {
    BaseApp.CONTEXT.hideDialog(id);
  }

  showMenu(anchor: HTMLElement, menuId: string, ...menuArgs: any[]) {
    BaseApp.CONTEXT.showMenu(
      anchor,
      {
        id: menuId,
        args: menuArgs,
      },
      (props: MenuProps) => this.createMenu(props.id, ...props.args), menuOption => this.onMenuOptionSelected(menuOption));
  }

  createMenu(menuId: string, ...menuArgs: any[]): MenuOption[] {
    return null;
  }

  onMenuOptionSelected(menuOption: MenuOption) {
  }
}

export type DialogFragmentProps = PageFragmentProps & {
  dialogProps?: DialogProps,
}

export type DialogFragmentState = PageFragmentState & {}

export abstract class DialogFragment<P extends DialogFragmentProps = DialogFragmentProps, S extends DialogFragmentState = DialogFragmentState> extends PageFragment<P, S> {

  getIntrinsicHeight(): number | string {
    return ((this.props.dialogProps?.flags & DIALOG_FLAG_SHOW_DIALOG_FULLSCREEN) !== 0) ? "100vh" : null;
  }
}

export type EditDialogFragmentProps<V> = PageFragmentProps & {
  initialValue?: V,
}

export type EditDialogFragmentState<V> = PageFragmentState & {
  value?: V,
}

export abstract class EditDialogFragment<V, P extends EditDialogFragmentProps<V> = EditDialogFragmentProps<V>, S extends EditDialogFragmentState<V> = EditDialogFragmentState<V>> extends DialogFragment<P, S> {

  protected onCreateState(): S {
    const editing = Boolean(this.props.initialValue);
    return {
      ...super.onCreateState(),
      title: editing ? "Edit" : "Add",
      actionButtonDisabled: !editing,
      value: editing ? this.cloneValue(this.props.initialValue) : this.createValue(),
    } as S;
  }

  protected styleFlags(): number {
    return PageFragment.STYLE_ACTION_PANEL_TYPE_BUTTON_FLAG;
  }

  protected getActionButtonText(): string {
    const editing = Boolean(this.props.initialValue);
    return editing ? "Update" : "Add";
  }

  protected abstract cloneValue(initialValue?: V): V;

  protected abstract createValue(): V;
}

export type SetupConfig = {
  iconType?: typeof SvgIcon,
  title?: string,
  text?: string,
}

export abstract class SetupFragment<P extends PageFragmentProps = PageFragmentProps, S extends PageFragmentState = PageFragmentState> extends DialogFragment<P, S> {

  protected getSetupConfig(): SetupConfig | null {
    return null;
  }

  protected styleFlags(): number {
    return PageFragment.STYLE_ACTION_PANEL_TYPE_BUTTON_FLAG;
  }

  renderContent(): React.ReactElement {
    const setupConfig = this.getSetupConfig();
    let IconType: typeof SvgIcon = setupConfig?.iconType;
    let setupHeader = this.setupHeader();
    return <StyledBoxColumn style={{width: DW_LG, minHeight: "50vh"}}>
      <Box style={{
        display: "flex",
        flexDirection: "column",
        gap: PD_SM,
        padding: PD_LG,
        margin: "auto",
        alignItems: "center",
        flexGrow: 0,
      }}>
        {IconType ? <IconType style={{fontSize: SZ_MD,}}/> : null}
        <Typography variant="h6">{setupConfig?.title}</Typography>
        {setupHeader ? setupHeader
          : <Typography style={{textAlign: "center"}}>{setupConfig?.text}</Typography>}
      </Box>
      {this.renderSetupContent()}
    </StyledBoxColumn>;
  }

  protected getActionButtonText(): string {
    return "Next";
  }

  setupHeader(): ReactElement {
    return null;
  }

  abstract renderSetupContent(): ReactElement;
}
