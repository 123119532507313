import React, {ReactElement} from "react";
import {Box, Tab, Tabs} from "@mui/material";
import {DIVIDER, PD_MD, SZ_LG, SZ_MD} from "./dimens";
import {Outlet} from "react-router-dom";
import {TabsContainer, TabsContainerProps, TabsContainerState} from "./TabsContainer";
import {PluginHostFragment} from "./PluginHostFragment";
import {BaseApp} from "./BaseApp";
import {gray, lightGray} from "./colors";

export type FooterTabsContainerProps<TAB_TYPE> = TabsContainerProps<TAB_TYPE> & {
  accessory?: ReactElement,
}

export type FooterTabsContainerState<TAB_TYPE> = TabsContainerState<TAB_TYPE> & {}

export abstract class FooterTabsContainer<TAB_TYPE, P extends FooterTabsContainerProps<TAB_TYPE>, S extends FooterTabsContainerState<TAB_TYPE>> extends TabsContainer<TAB_TYPE, P, S> {

  render() {
    const tabs = TabsContainer.expandGroups(this.props.tabs);
    return <Box
      style={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        position: "relative",
        paddingBottom: SZ_LG,
        overflowY: "scroll"
      }}>
      <Box id="footer-tab-container-toolbar" style={{
        display: "flex",
        flexShrink: 0,
        height: SZ_MD,
        alignItems: "center",
        paddingLeft: PD_MD,
        paddingRight: PD_MD,
        gap: PD_MD,
        left: 0,
        right: 0,
        background: "white",
        borderBottom: DIVIDER,
        position: "fixed",
        pointerEvents: "none",
        zIndex: 1,
      }}>
        {this.renderToolbar()}
      </Box>
      <Box style={{flexGrow: 1, display: "flex", flexDirection: "column", background: lightGray}}>
        <Outlet/>
        <PluginHostFragment hostId={BaseApp.CONTEXT.getAppConfig().name + "-" + this.props.tabs.containerId}/>
      </Box>
      <Box style={{
        display: "flex",
        flexShrink: 0,
        height: SZ_LG,
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: PD_MD,
        paddingRight: PD_MD,
        paddingBottom: BaseApp.CONTEXT.getAppConfig().safeAreaInsets?.bottom,
        gap: PD_MD,
        borderTop: DIVIDER,
        left: 0,
        right: 0,
        bottom: 0,
        background: BaseApp.CONTEXT.getAppConfig().theme?.palette.background.paper,
        position: "fixed",
      }}>
        <Tabs
          TabIndicatorProps={{
            style: {
              height: 0,
              maxWidth: 64,
              marginLeft: 24,
              borderRadius: 2,
            }
          }}
          style={{position: "absolute"}}
          centered
          value={this.state.tabPath}
          onChange={(event, value) => this.props.path.navigate(value)}>
          {tabs.items.filter(tab => !tab.hidden).map(tab => {
            const IconType = tab.iconType;
            if (tab.variant === "space") {
              return <span style={{width: 120}}/>;
            }
            return <Tab className="tabButton" icon={<IconType style={{width: 32, height: 32}}/>}
                        label={tab.text}
                        value={tab.path}
                        style={BaseApp.CONTEXT.getAppConfig().styleOverrides?.tabButton}
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: gray,
                          }
                        }}/>;
          })}
        </Tabs>
      </Box>
    </Box>
  }

  protected renderToolbar(): ReactElement {
    return null;
  }
}
