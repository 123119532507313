import React, {ReactElement} from "react";
import {Box, Button, Typography} from "@mui/material";
import {DIVIDER, PD_LG, PD_MD, PD_SM, SZ_LG, SZ_MD, SZ_SM} from "../../shared/dimens";
import {ArrowBackOutlined} from "@mui/icons-material";
import {StyledBoxColumn, StyledBoxRow, StyledContainer, StyledSpan} from "../../shared/StyledComponents";
import {GameConfig, GameData, GameOverResult} from "./types";
import {getAuth} from "@firebase/auth";
import {Game} from "../types";
import {
  FullscreenDialogWithTitleFragment,
  FullscreenDialogWithTitleFragmentProps,
  FullscreenDialogWithTitleFragmentState
} from "../../shared/FullscreenDialogWithTitleFragment";
import {BaseApp} from "../../shared/BaseApp";

export interface GameFragmentListener {

  onGameReset();

  onGameEnded();

  onBoardUpdated();

  onGameOver(result: GameOverResult, ...args);

  onStatusChanged(status: string);

}

export type GameFragmentProps = FullscreenDialogWithTitleFragmentProps & {
  gameFragmentListener?: GameFragmentListener,
}

export type GameFragmentState<GC extends GameConfig, GD extends GameData<any>> =
  FullscreenDialogWithTitleFragmentState
  & {
  gameConfig?: GC,
  gameData?: GD,
}

export abstract class GameFragment<P extends GameFragmentProps, GC extends GameConfig, GD extends GameData<any>, S extends GameFragmentState<GC, GD>> extends FullscreenDialogWithTitleFragment<P, S> {

  protected readonly auth = getAuth();

  getTitle(): string {
    return this.getGame().name;
  }

  protected abstract getGame(): Game;

  render() {
    if (!this.state.gameConfig) {
      return this.renderConfigContent();
    }
    if (!this.state.gameData) {
      return this.renderPleaseWait();
    }
    return super.render();
  }

  protected finishGame(result: GameOverResult) {
    this.state.gameData.gamePlay.finish();
    this.props.gameFragmentListener?.onGameOver(result);
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
    if (prevState.gameConfig !== this.state.gameConfig) {
      this.reload(true);
    }
  }

  renderContent(): React.ReactElement {
    return <Box style={{display: "flex", flexDirection: "column", flexGrow: 1, position: "relative"}}>
      {this.renderGameToolbar()}
      <StyledContainer>
        {this.renderGameContent()}
      </StyledContainer>
    </Box>;
  }

  protected abstract renderConfigContent(): ReactElement;

  protected renderConfigContentForGame(game: Game, configOptions: ReactElement) {
    return <Box className="footer-tab-fragment-header"
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100vh",
                  flexDirection: "column",
                  position: "relative",
                  paddingBottom: SZ_LG,
                  background: game.color,
                }}>
      <Box style={{
        display: "flex",
        flexShrink: 0,
        height: SZ_MD,
        alignItems: "center",
        paddingLeft: PD_MD,
        paddingRight: PD_MD,
        gap: PD_MD,
        left: 0,
        right: 0,
      }}>
        <Button onClick={() => BaseApp.CONTEXT.hideDialog()}>Back</Button>
      </Box>
      <StyledBoxColumn style={{flexGrow: 1, alignItems: "center", justifyContent: "center", gap: PD_LG, marginTop: -SZ_MD}}>
        <img src={game.icon} style={{width: 96}}/>
        <Typography style={{fontFamily: "Gabarito, sans-serif"}} variant="h4">{game.name}</Typography>
        <Typography>{game.description}</Typography>
        <StyledBoxColumn style={{width: 240}}>
          {configOptions}
          {!this.auth.currentUser ? <Button variant="outlined" onClick={() => {
            }}>Log in</Button>
            : null}
        </StyledBoxColumn>
      </StyledBoxColumn>
    </Box>
  }

  private renderGameToolbar() {
    return <StyledBoxRow style={{padding: PD_SM, boxSizing: "border-box", height: SZ_SM, borderBottom: DIVIDER, alignItems: "center"}}>
      {this.renderToolbarTitle()}
      <StyledSpan/>
      {this.renderToolbarButtons()}
    </StyledBoxRow>
  }

  protected renderToolbarTitle(): ReactElement {
    return null;
  }

  protected renderToolbarButtons(): ReactElement {
    return null;
  }

  protected abstract renderGameContent(): ReactElement;
}